import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeAllSelectedService } from "../../../redux/feature/selectedService";

const customerType = [
  {
    text: "Men",
    imgSrc: "/male-icon.jpg",
    path: "/customer/service/male",
  },
  {
    text: "Women",
    imgSrc: "/female-icon.jpg",
    path: "/customer/service/female",
  },
];

function SectionLeft() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleNavigate(path){
    navigate(path)
    dispatch(removeAllSelectedService())
  }

  return (
    <div className="grid text-center grid-rows-12 p-2 h-full">
      {/* <p className="text-xl row-span-2 font-semibold sm:text-4xl sm:p-2">
        What are you looking for ?
      </p> */}

      <div className="row-span-10 w-full flex justify-between gap-24 flex-wrap items-center">
        {customerType.map((item, index) => (
          <div
            key={index}
            className="h-20 w-20 sm:h-80 sm:w-80 shadow-md flex flex-col p-2 m-1 justify-center items-center transition duration-300 hover:-translate-y-3 bg-slate-500 rounded-lg"
            onClick={() => {
              handleNavigate(item.path);
            }}
          >
            <img src={item.imgSrc} className="w-full h-5/6" />
            <p className="py-2 font-bold text-white text-xl">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SectionLeft;
