import React from "react";
import Category from "../../../components/CustomerComponent/Categories/Category";
import Header from "../../../components/CustomerComponent/Header/Header";

function Dashboard() {
  return (
    <>
      <div className="w-full min-h-screen bg-slate-200 grid grid-rows-12">
        <div className="bg-slate-800 row-span-2">
          <Header />
        </div>
        <div className="row-span-10 h-full w-full">
          <Category />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
