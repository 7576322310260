import { createSlice } from "@reduxjs/toolkit";


const initialOrderBook = {
    id: null,
    data : [],
    waitingTime: null,
    assignStaffIds: []
}

export const OrderBookSlice = createSlice({
    name: "Order-Book",
    initialState: initialOrderBook,
    reducers: {
        setOrderBook: (state, action) => {
            state.id = action.payload.id,
            // state.data = action.payload.data?.map((service) => ({...service, isChangedStylist: false})),
            state.data = action.payload.data,
            state.waitingTime = action?.payload?.data[0]?.orderBookEntity?.totalWaitingTime || null
        },
        updateWaitingTime: (state, action) => {
            state.waitingTime = action.payload || state.waitingTime
        },
        updateChangeStylistAccess : (state, action) => {
            state.data = state.data.map((service) => service.id === action.payload.id ? service.isChangedStylist = true : service)
        },
        addServiceChangedId: (state, action) =>{
            state.assignStaffIds = [...state.assignStaffIds, action.payload]
        },
        resetOrderBook: (state, action) => {
            return initialOrderBook
        }
    },
});

export const { setOrderBook, resetOrderBook, updateWaitingTime, addServiceChangedId } = OrderBookSlice.actions;

export default OrderBookSlice.reducer;
