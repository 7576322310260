import { createSlice } from "@reduxjs/toolkit";

export const EmployeePayoutSlice = createSlice({
  name: "Payout",
  initialState: {
    content: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
  },
  reducers: {
    setPayout: (state, action) => {
      state.content = [...action.payload.content];
      state.page = action.payload.page;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
    },
    updatePayout: (state, action) => {
      state.content = state.content.map((item) =>
        item.staffId === action.payload.staffId
          ? action.payload
          : item
      );
    },
  },
});

export const { setPayout, updatePayout } = EmployeePayoutSlice.actions;
export default EmployeePayoutSlice.reducer;
