import React from "react";
import SectionLeft from "./SectionLeft";

function Category() {
  return (
    <div className="w-full h-full relative">
      <img
        src="/dashboard-background.jpg"
        className="w-full h-full absolute top-0 left-0 z-2 opacity-30"
      />
      <div className="flex flex-center absolute h-full w-full z-50">
        <SectionLeft />
      </div>
    </div>
  );
}

export default Category;
