// export const PayoutData = [
//     {
//         "employeeName" : "Satvick Pathak",
//         "employeeCode" : "34546",
//         "salary" : "35365 INR",
//         "nextPayoutDate" : "05-03-2024",
//         "outletAddress": "Lal Bangla, Kanpur",
//         "outstandingAmount": "2314 INR",
//         "bankAccountNo": "345665656788",
//         "IFSCCode": "ICIC000233"
//       },
//     {
//         "employeeName" : "Vinay Singh",
//         "employeeCode" : "54554",
//         "salary" : "20000 INR",
//         "nextPayoutDate" : "28-02-2024",
//         "outletAddress": "Civil Lines, Kanpur",
//         "outstandingAmount": "14567 INR",
//         "bankAccountNo": "343456788",
//         "IFSCCode": "ICIC000033"
//       },
// ]

export const EditPayout = [
  {
    label: "Transaction Amount",
    type : "number",
    name: "transactionAmount",
    required: true
  },
  {
    label: "Remark",
    type : "text",
    name: "Remark",
    required: true
  },
  // {
  //   label: "Employee Code",
  //   type : "text",
  //   name : "employeeCode",
  //   required: true
  // },
  // {
  //   label: "Salary",
  //   type : "text",
  //   name : "salary",
  //   required: true
  // },
  // {
  //   label: "Next Payout Date",
  //   type : "date",
  //   name: "nextPayoutDate",
  //   required :true
  // },
  // {
  //   label: "Outlet Address",
  //   type : "address",
  //   name : "outletAddress",
  //   required : true
  // },
  // {
  //   label: "Outstanding Amount",
  //   type : "text",
  //   name : "outstandingAmount",
  //   required : true
  // },
  // {
  //   label: "Bank Account No",
  //   type : "text",
  //   name : "bankAccountNo",
  //   required : true
  // },
  // {
  //   label: "IFSC Code",
  //   type : "text",
  //   name : "IFSCCode",
  //   required : true
  // },
]

export const empPayoutSummary = [
  {
    label: "Staff Id",
    value: "staffId"
  },
  {
    label: "Staff Name",
    value: "staffName"
  },
  {
    label: "Transaction Amount",
    value: "transactionAmount"
  },
  {
    label: "Other Remark",
    value: "otherRemark",
  },
  {
    label: "Transaction Date",
    value: "transactionDate",
  },
  {
    label: "Payout Remark",
    value: "payoutRemark"
  },
  {
    label: "Balance Amount",
    value: "balanceAmount",
  },
  {
    label: "Salary",
    value: "salary",
  },
  {
    label: "Salary Deduction Amount",
    value: "salaryDeductionAmount",
  },
];

export const EMP_FEEDBACK_DATA = {
  LABEL: "Select the Employee",
  OVER_ALL: "OVERALL",
  OVER_ALL_RATING: "Over All Rating",
};

export const EMP_DATA_KEY = {
  STAFF_NAME: "staffName",
  // STATUS: "status",
  // GENDER: "gender",
  STAFF_ID: "staffId",
  TRANSACTION_AMOUNT: "transactionAmount",
  OTHER_REMARK: "otherRemark",
  TRANSACTION_DATE: "transactionDate",
  PAYOUT_REMARK: "payoutRemark",
  BALANCE_AMOUNT: "balanceAmount",
  SALARY_DEDUCTION_AMOUNT: "SalaryDeductionAmount",
  // OVER_ALL_RATING: "overAllRating",
  // MOBILE_NO: "mobileNo",
  // ADDRESS: "address",
  // CHECKED_IN: "checkedIn",
  // IS_AVAILABLE: "isAvailable",
  SALARY: "salary",
  // EMPLOYEE_CODE: "employeeCode"
};

const typeData = {
  name: "string",
  status: "ACTIVE",
  gender: "string",
  overAllRating: 0,
  id: 0,
  mobileNo: 0,
  address: "string",
  checkedIn: true,
  isAvailable: true,
};

export const headCellsEmpFeedBack = [
  {
    id: EMP_DATA_KEY.STAFF_ID,
    numeric: false,
    disablePadding: false,
    label: "Staff Id",
  },
  {
    id: EMP_DATA_KEY.STAFF_NAME,
    numeric: false,
    disablePadding: false,
    label: "Staff Name",
  },
  {
    id: EMP_DATA_KEY.SALARY,
    numeric: false,
    disablePadding: false,
    label: "Salary",
  },
  {
    id: EMP_DATA_KEY.BALANCE_AMOUNT,
    numeric: false,
    disablePadding: false,
    label: "Balance Amount",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
];

export const rowsEmployeeFeedBack = [
  // {
  //   id: 1,
  //   name: "John Doe",
  //   status: "ACTIVE",
  //   gender: "Male",
  //   overAllRating: 3.5,
  //   mobileNo: 1234567890,
  //   address: "123 Main St, Anytown USA",
  //   checkedIn: true,
  //   isAvailable: true,
  //   salary: 30000,
  //   employeeCode : 11111
  // },
  // {
  //   id: 2,
  //   name: "Jane Smith",
  //   status: "INACTIVE",
  //   gender: "Female",
  //   overAllRating: 4,
  //   mobileNo: 9876543210,
  //   address: "456 Oak Ave, Anytown USA",
  //   checkedIn: false,
  //   isAvailable: false,
  //   salary: 30450,
  //   employeeCode : 22222
  // },
  // {
  //   id: 3,
  //   name: "Michael Johnson",
  //   status: "ACTIVE",
  //   gender: "Male",
  //   overAllRating: 4.2,
  //   mobileNo: 5555555555,
  //   address: "789 Pine St, Anytown USA",
  //   checkedIn: true,
  //   isAvailable: true,
  //   salary: 23000,
  //   employeeCode : 33333
  // },
  // {
  //   id: 4,
  //   name: "Emily Davis",
  //   status: "INACTIVE",
  //   gender: "Female",
  //   overAllRating: 2,
  //   mobileNo: 1111111111,
  //   address: "321 Elm St, Anytown USA",
  //   checkedIn: false,
  //   isAvailable: false,
  //   salary: 12343,
  //   employeeCode : 44444
  // },
  // {
  //   id: 5,
  //   name: "David Wilson",
  //   status: "ACTIVE",
  //   gender: "Male",
  //   overAllRating: 4.9,
  //   mobileNo: 9999999999,
  //   address: "555 Pine St, Anytown USA",
  //   checkedIn: true,
  //   isAvailable: true,
  //   salary: 43567,
  //   employeeCode : 55555
  // },
  // {
  //   id: 6,
  //   name: "Olivia Thompson",
  //   status: "INACTIVE",
  //   gender: "Female",
  //   overAllRating: 3.7,
  //   mobileNo: 8888888888,
  //   address: "777 Oak Ave, Anytown USA",
  //   checkedIn: false,
  //   isAvailable: false,
  //   salary: 54000,
  //   employeeCode : 66666
  // },
];
