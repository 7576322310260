export const empConfigBody = (values) => {
  delete values.specialty;
  return {
    status: "UNHOLD",
    overAllRating: 5,
    checkedIn: false,  // changed so that employee is checkedout by default
    isAvailable: true,  // changed so that mployee is available when created
    password: "salon@123",
    // balanceAmount: 0,
    ...values,
  };
};

export const imgUploadStaff = (id, img, salonId) => {
  return {
    userPhotoUrl: img,
    staffId: id,
  };
};
