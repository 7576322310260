import React, { useEffect, useState } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Button as MUIButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import PopWindow from "../../../components/CustomerComponent/Checkout/PopWindow";
import { useSelector } from "react-redux";

function ItemsView() {
  const Services = useSelector((state) => state.OrderBook?.data || {});
  const allChangedStaffIds = useSelector((state) => state.OrderBook.assignStaffIds)
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handleIsChange = (staffId, serviceId) => {
    setSelectedStaffId(staffId);
    setSelectedServiceId(serviceId);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    background: "linear-gradient(to right,#7331ff, white)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(()=>{console.log("parent",allChangedStaffIds)},[allChangedStaffIds])

  return (
    <Box
      className="h-full w-full border-2 border-gray-500 rounded-lg flex flex-col items-center overflow-hidden"
      sx={{
        borderRadius: 2,
        borderColor: "gray.500",
        borderWidth: 2,
        overflow: "hidden",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
        component="p"
        sx={{
          py: 2,
          px: 4,
          width: "100%",
          fontWeight: "medium",
          textAlign: "center",
        }}
      >
        Items
      </Typography>
      <Box sx={{ width: "100%", px: "10px", overflowY: "scroll", flexGrow: 1 }}>
        <Stack spacing={2}>
          {Array.isArray(Services) &&
            Services?.map((item) => (
              <Item key={item.id}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: 2,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="h6"
                      component="p"
                      sx={{ textAlign: "left", textWrap: "wrap" }}
                    >
                      {item?.salonServicesEntity?.serviceName}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ textAlign: "left" }}
                    >
                      &#8377;{item?.salonServicesEntity?.price}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="p"
                        sx={{ textAlign: "left" }}
                      >
                        {item?.salonStaffEntity?.name}
                      </Typography>

                      <Typography
                        variant="body2"
                        component="p"
                        sx={{ textAlign: "left" }}
                      >
                        {item?.salonStaffEntity?.gender}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 2,
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <AccessTimeFilledIcon
                          fontSize="small"
                          sx={{ mr: 1 }}
                        />
                        Rating(5) : {item?.salonStaffEntity?.overAllRating}
                      </Typography>
                    </Box>
                    <MUIButton
                      onClick={() =>
                        handleIsChange(item?.salonStaffEntity?.id, item.id)
                      }
                      variant="contained"
                      disabled={allChangedStaffIds.includes(item?.id)}
                      color="primary"
                      style={{ paddingX: 5, paddingY: 2 }}
                    >
                      Change Stylist
                    </MUIButton>
                  </Box>
                </Box>
              </Item>
            ))}
        </Stack>
      </Box>

      {isPopupOpen && (
        <PopWindow
          staffId={selectedStaffId}
          serviceId={selectedServiceId}
          isOpen={isPopupOpen}
          handleIsOpen={handleClosePopup}
        />
      )}
    </Box>
  );
}

export default ItemsView;
