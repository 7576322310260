// export const BASE_URL = {
//   CUSTOMER_SERVICE_URL: "https://api.smartsalonbot.com/customer-service/",
//   SALON_SERVICE_URL: "https://api.smartsalonbot.com/salon-service/",
//   AUTH_SERVICE_URL: "https://api.smartsalonbot.com/salon-auth-service/",
//   FEEDBACK_SERVICE_URL: "https://api.smartsalonbot.com/feedback-service/",
//   INVENTORY_SERVICE_URL: "https://api.smartsalonbot.com/inventory-service/",
//   LOG_BOOK_SERVICE: "https://api.smartsalonbot.com/log-book-service/",
// };

export const IMG_URL = {
  BASE_SERVICE_IMG: "https://salonbot-bucket.s3.ap-south-1.amazonaws.com/",
  SALON_CUSTOMER_PORTAL_VIDEO: "https://salon-s3-bucket.s3.ap-south-1.amazonaws.com/8.mp4",
};

export const BASE_URL = {
  CUSTOMER_SERVICE_URL: "https://api-dev.smartsalonbot.com/customer-service/",
  SALON_SERVICE_URL: "https://api-dev.smartsalonbot.com/salon-service/",
  AUTH_SERVICE_URL: "https://api-dev.smartsalonbot.com/salon-auth-service/",
  FEEDBACK_SERVICE_URL: "https://api-dev.smartsalonbot.com/feedback-service/",
  INVENTORY_SERVICE_URL: "https://api-dev.smartsalonbot.com/inventory-service/",
  LOG_BOOK_SERVICE: "https://api-dev.smartsalonbot.com/log-book-service/",
};

// export const IMG_URL = {
//   BASE_SERVICE_IMG: "https://salonbot-bucket.s3.ap-south-1.amazonaws.com/",
// };
