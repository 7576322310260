import { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";

// MUI
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// components
import {
  EMP_DATA_KEY,
  headCellsEmpFeedBack,
  rowsEmployeeFeedBack,
  EditPayout,
} from "./PayoutConstants";

import EnhancedTableHead from "../../../../../components/Mui/table/Table";

// helper
import { getComparator, stableSort } from "../../../../../helper";

// icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import { useSelector } from "react-redux";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../../../helper/Common/constant";

export function PayoutEmpTable({ handleDialogueOpen, updateStaffPayoutData }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const PayoutData = useSelector((state) => state.payout);

  // payout edit modal
  const [payoutRemark, setPayoutRemark] = useState("");
  const [amountDeductionRemark, setAmountDeductionRemark] = useState("");
  const [transactionAmount, setTransactionAmount] = useState(0);
  const [isDeductSalary, setIsDeductSalary] = useState(false);
  const [deductionAmount, setDeductionAmount] = useState(0);
  const [rowData, setRowData] = useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowsEmployeeFeedBack.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(
    () =>
      stableSort(
        PayoutData?.content || [],
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage]
  );

  useEffect(() => console.log(), [PayoutData.content])

  return (
    <>
      <Stack width={"100%"}>
        <TableContainer>
          <Toolbar sx={{ display: "flex", flexWrap: "wrap" }}>
            <Grid container spacing={2} alignItems="center" width={"45%"}>
              <Grid item>
                <SearchIcon color="inherit" style={{ display: "block" }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by Name or Employee ID"
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: "default" },
                  }}
                  variant="standard"
                  size="small"
                  // onChange={(e) => setValue(e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <Chip label="Search" />
              </Grid>
            </Grid>
            <Grid container justifyContent={"right"} width={"55%"}>
              {/* <Grid item xs={3}>
              <Chip label='Export' width={'25px'} color='primary' icon={<FileDownloadSharpIcon/>} />
            </Grid> */}
            </Grid>
          </Toolbar>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={PayoutData?.content?.length}
              headCells={headCellsEmpFeedBack}
            />
            <TableBody>
              {visibleRows.length > 0 &&
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.staffId);
                  const labelId = `employee-table-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      size={"medium"}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ paddingLeft: 3 }}
                      >
                        {row[EMP_DATA_KEY.STAFF_ID] || "NA"}
                      </TableCell>

                      <TableCell align="left">
                        {row[EMP_DATA_KEY.STAFF_NAME] || ""}
                      </TableCell>
                      <TableCell align="left">
                        {row[EMP_DATA_KEY.SALARY] || 0}
                      </TableCell>
                      <TableCell align="left">
                        {row[EMP_DATA_KEY.BALANCE_AMOUNT] || 0}
                      </TableCell>
                      <TableCell align="right">
                        <RemoveRedEyeIcon
                          onClick={(event) => handleDialogueOpen(event, row)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <EditSharpIcon
                          color="success"
                          onClick={() => {
                            setRowData(row)
                            setModal({ ...modal, isOpen: true })
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsEmployeeFeedBack.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>

      <Dialog
        open={modal.isOpen}
        onClose={() => setModal({ ...modal, isOpen: false })}
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            // AddEmployees();
            // setSpecialityInsert([]);
            const payload = {
              staffId: rowData.staffId,
              transactionAmount: Number(transactionAmount),
              otherRemark: amountDeductionRemark,
              payoutRemark: payoutRemark,
              salaryDeductionAmount: Number(deductionAmount)
            }
            updateStaffPayoutData(payload, rowData)
            setModal({ ...modal, isOpen: false });
            setTransactionAmount(0);
            setPayoutRemark("");
            setDeductionAmount(0);
            setIsDeductSalary(false)
            setAmountDeductionRemark("");
          },
        }}
      >
        <DialogTitle>Edit Payout</DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText>* fields are necessary</DialogContentText> */}

          <Stack>
            <TextField
              autoFocus
              required
              margin="dense"
              id={"transactionAmount"}
              name={"transactionAmount"}
              label={"Transaction Amount"}
              variant={MUI_VARIANT}
              size={MUI_SIZE}
              sx={{
                height: MUI_TEXT_FIELD_HIGHT,
              }}
              type={"number"}
              fullWidth
              value={transactionAmount}
              onChange={(e) => setTransactionAmount(e.target.value)}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id={"remark"}
              name={"payoutRemark"}
              label={"Remark"}
              variant={MUI_VARIANT}
              size={MUI_SIZE}
              sx={{
                height: MUI_TEXT_FIELD_HIGHT,
              }}
              type={"text"}
              fullWidth
              value={payoutRemark}
              onChange={(e) => setPayoutRemark(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="deductSalary"
                  name="deductSalary"
                  checked={isDeductSalary}
                  onChange={(e) => {
                    setIsDeductSalary(!isDeductSalary);
                  }}
                  inputProps={{ "aria-label": "Deduct Amount from Salary" }}
                  sx={{
                    height: MUI_TEXT_FIELD_HIGHT,
                  }}
                />
              }
              label="Deduct Amount from Salary"
            />

            {
              isDeductSalary && (
                <>
                <TextField
                autoFocus
                required
                margin="dense"
                id={"deductionAmount"}
                name={"deductionAmount"}
                label={"Deduction Amount"}
                variant={MUI_VARIANT}
                size={MUI_SIZE}
                sx={{
                  height: MUI_TEXT_FIELD_HIGHT,
                }}
                type={"number"}
                fullWidth
                value={deductionAmount}
                onChange={(e) => setDeductionAmount(e.target.value)}
              />
              <TextField
              autoFocus
              required
              margin="dense"
              id={"amountDeductionRemark"}
              name={"amountDeductionRemark"}
              label={"Amount Deduction Remark"}
              variant={MUI_VARIANT}
              size={MUI_SIZE}
              sx={{
                height: MUI_TEXT_FIELD_HIGHT,
              }}
              type={"text"}
              fullWidth
              value={amountDeductionRemark}
              onChange={(e) => setAmountDeductionRemark(e.target.value)}
            />
                </>
              )
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal({ ...modal, isOpen: false })}>
            Cancel
          </Button>
          <Button type="submit">Pay</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PayoutEmpTable.propTypes = {
  handleDialogueOpen: PropTypes.func,
};
