import {
  EMPLOYEE_CONFIG_FIELD_NAME,
  EMP_CONFIG_MODE,
} from "../constantEmpConfig";

export const initialSpecialty = {
  [EMPLOYEE_CONFIG_FIELD_NAME.SPECIALTY_NAME]: "",
};

export const initialValueEmpConfig = (selectedData) => {
  const { mode, data } = selectedData;
  const isEditMode = mode === EMP_CONFIG_MODE.EDIT;

  return {
    [EMPLOYEE_CONFIG_FIELD_NAME.NAME]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.NAME]
      : "",
    [EMPLOYEE_CONFIG_FIELD_NAME.GENDER]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.GENDER]
      : "",
    [EMPLOYEE_CONFIG_FIELD_NAME.MOBILE_NO]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.MOBILE_NO]
      : "",
    [EMPLOYEE_CONFIG_FIELD_NAME.ADDRESS]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.ADDRESS]
      : "",
    [EMPLOYEE_CONFIG_FIELD_NAME.PASSWORD]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.PASSWORD]
      : "",
    [EMPLOYEE_CONFIG_FIELD_NAME.SALARY]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.SALARY]
      : 0,
    [EMPLOYEE_CONFIG_FIELD_NAME.BALANCE_AMOUNT]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.BALANCE_AMOUNT]
      : 0,
    [EMPLOYEE_CONFIG_FIELD_NAME.OVER_ALL_RATING]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.OVER_ALL_RATING]
      : "",
    [EMPLOYEE_CONFIG_FIELD_NAME.IMG_URL]: isEditMode
      ? data[EMPLOYEE_CONFIG_FIELD_NAME.IMG_URL]
      : "",
    [EMPLOYEE_CONFIG_FIELD_NAME.SPECIALTY]: isEditMode
      ? data.servicesList.map((ele) => {
          return ele.id;
        })
      : [],
  };
};
