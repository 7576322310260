export const EMP_CONFIG_MODE = {
  ADD: "add",
  EDIT: "edit",
};

export const EMP_CONFIG_STATUS = {
  HOLD: "HOLD",
  UNHOLD: "UNHOLD",
};

export const EMPLOYEE_CONFIG_FIELD_NAME = {
  ID: "id",
  NAME: "name",
  STATUS: "status", // This Status = Active ENUM [HOLD | UNHOLD]
  STATUS_ATTENDANCE: "statusAttendance", // This for external api request [ACTIVE | INACTIVE]
  GENDER: "gender",
  OVER_ALL_RATING: "overAllRating",
  PASSWORD: "password",
  MOBILE_NO: "mobileNo",
  ADDRESS: "address",
  CHECKED_IN: "checkedIn",
  IS_AVAILABLE: "isAvailable",
  SPECIALTY: "serviceIdList",
  SPECIALTY_NAME: "specialtyName",
  SPECIALTY_RATING: "specialtyRating",
  IMG_UPLOAD: "imgUpload",
  IMG_URL: "imageUrl",
  SALARY: "salary",
  BALANCE_AMOUNT: "balanceAmount"
};

export const specialtyList = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

{
  /* <TableCell align='justify'>Employee Id</TableCell>
<TableCell align='justify'>Name</TableCell>
<TableCell align='justify'>Gender</TableCell>
<TableCell align='justify'>Age</TableCell>
<TableCell align='justify'>Edit</TableCell>
<TableCell align='justify'>Status</TableCell> */
}
export const headCellsEmpConfig = [
  {
    id: EMPLOYEE_CONFIG_FIELD_NAME.ID,
    numeric: false,
    disablePadding: false,
    label: "Employee Id",
  },
  {
    id: EMPLOYEE_CONFIG_FIELD_NAME.NAME,
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: EMPLOYEE_CONFIG_FIELD_NAME.STATUS,
    numeric: false,
    disablePadding: false,
    label: "Unhold/Hold",
  },
  {
    id: EMPLOYEE_CONFIG_FIELD_NAME.OVER_ALL_RATING,
    numeric: false,
    disablePadding: true,
    label: "Over All Rating",
  },
  // {
  //   id: EMPLOYEE_CONFIG_FIELD_NAME.STATUS_ATTENDANCE,
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Is Active",
  // },

  // {
  //   id: EMPLOYEE_CONFIG_FIELD_NAME.IS_AVAILABLE,
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Available',
  // },
  // {
  //   id: EMPLOYEE_CONFIG_FIELD_NAME.CHECKED_IN,
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Checked In',
  // },

  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
  {
    id: "Edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
];
