import React from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Header() {
  const navigate = useNavigate();

  const Logo = useSelector((state) => state.UploadLogo.logo);
  const SalonDetails = useSelector((state) => state?.SalonDetails?.Salon_data);

  return (
    <div className="flex flex-col sm:flex-row sm:justify-between items-center w-full h-full px-10 py-2">
      <div className="h-[100px] sm:h-[150px] flex items-center ">
        <img
          src={Logo}
          className="h-[50px] w-[125px] sm:h-[75px] sm:w-[200px] object-contain"
        />
      </div>
      <div className="flex-grow text-xl text-center font-bold text-white">
        {SalonDetails.address}
        <FmdGoodIcon fontSize="large" className="m-2 sm:m-4" />
      </div>
      <div className="flex items-center text-xl text-center font-bold text-white sm:text-3xl">
        <p
          className="flex items-center font-semibold cursor-pointer mr-6 text-md p-3 rounded-full shadow-md bg-purple-600"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosIcon fontSize="large" />
        </p>
      </div>
    </div>
  );
}

export default Header;
