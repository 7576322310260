import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import DiscountIcon from "@mui/icons-material/Discount";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
import {
  DELETE_ORDER_BOOK,
  GET_ALL_LOG_BOOK_BY_ORDER_BOOK_ID,
} from "../../../api/salon-api/log-book-service";
import { resetOrderBook, setOrderBook } from "../../../redux/feature/orderBookSlice";
import NavigationTimer from "./NavigationTimer";
import ItemsView from "./item-view";
import Ticket from "./ticket";
import PaymentSummary from "./paymentSummary";

function Checkout() {
  const navigate = useNavigate();
  const [totalCost, setTotalCost] = useState(0);
  const Logo = useSelector((state) => state.UploadLogo.logo);
  const id = useSelector((state) => state.OrderBook?.id || null);
  const data = useSelector((state) => state.OrderBook?.data || {});
  const waitingTime = useSelector((state) => state.OrderBook?.waitingTime || null);
  const TicketNoData = useSelector((state) => state.OrderBook?.data[0] || {});
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateLogBook();

  const GetOrderBookDetails = async (id) => {
    if (id) {
      try {
        const response = await axiosPrivate({
          method: "GET",
          url: `${GET_ALL_LOG_BOOK_BY_ORDER_BOOK_ID}/${id}`,
        });
        if (Array.isArray(response.data)) {
          dispatch(setOrderBook({ id: id, data: response.data.map((service) => ({...service, isChangedStylist: false})) }));
          getTotalCost(response.data);
        } else {
          dispatch(setOrderBook({ id: id, data: Array.from(response.data) }));
          getTotalCost(Array.from(response.data));
        }
      } catch (error) {
        console.error("Error fetching Order Book data:", error);
      }
    }
  };

  const removeOrderBookDetails = async (id, redirect = "/") => {
    if (id) {
      try {
        await axiosPrivate({
          method: "DELETE",
          url: `${DELETE_ORDER_BOOK}/${id}`,
        });
        window.localStorage.removeItem("orderBookId");
        dispatch(resetOrderBook());
        navigate(redirect);
      } catch (error) {
        console.error("Error resetting Order Book data:", error);
      }
    }
  };

  useEffect(() => {
    GetOrderBookDetails(id);
  }, [id]);

  const getTotalCost = (responseData) => {
    let actualCost = 0;
    responseData.forEach((element) => {
      actualCost += element.cost;
    });
    setTotalCost(actualCost);
  };

  return (
    <div className="grid relative h-full">
      <img src="/export-bg.png" className="img absolute -z-20" />
      <div className="h-[100px] sm:h-[150px] row-span-2 flex justify-center items-center sm:justify-between px-10">
        <img
          src={Logo}
          className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px] object-contain"
        />
        <p
          className="hidden sm:flex sm:text-3xl sm:font-semibold sm:items-center sm:justify-center sm:gap-2 sm:cursor-default"
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon fontSize="large" /> back
        </p>
      </div>

      <div className="flex justify-end h-full items-center text-4xl px-4 text-center font-bold text-black sm:text-7xl">
        <p
          className="sm:hidden text-2xl font-semibold flex items-center gap-2 cursor-default"
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon fontSize="medium" />
          back
        </p>
      </div>

      <div className="bg-white h-full w-full mt-10 sm:mt-2 p-4 flex flex-col">
        <div className="sm:h-3/6 h-full w-full flex flex-col sm:flex-row justify-center items-center">
          <div className="w-full sm:w-2/3 h-full flex flex-col items-center gap-4">
            {data && id && <ItemsView />}
            <div className="w-full sm:h-1/6 border-2 border-gray-500 p-3 sm:px-3 flex items-center rounded-lg sm:text-2xl font-semibold gap-2">
              <DiscountIcon fontSize="large" className="text-green-400" />
              Coupons & Offers{" "}
              <p className="font-normal text-xl">(currently unavailable)</p>
            </div>
          </div>

          <div className="w-full sm:w-1/3 h-full m-4 flex items-center sm:flex-col">
            {id && data && (
              <Ticket
                data={TicketNoData?.orderBookEntity?.token}
                waitingTime={waitingTime}
              />
            )}
            {id && (
              <NavigationTimer
                duration={180000} // 3 minutes in milliseconds
                onExpire={() => removeOrderBookDetails(id, "/")}
              />
            )}
          </div>
        </div>
        {data && id && <PaymentSummary TotalPrice={totalCost} />}

        <div className="w-full h-1/6">
          <p className="font-semibold text-lg">Cancellation Policy</p>
          <p className="font-normal">
            Please pay the price only if you are confirmed for the service at
            your own risk. Service payments will not be refunded.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
