import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import {
  getDateFormatYYYYMMDD,
  getTimeFormat,
} from "../../../../../helper/Common/DateFunctions";

export default function TimeSheetTable({ data }) {
  const CalculateTotalTime = () => {
    let accumulatedTime = 0;
    data.forEach((item) => {
      accumulatedTime += item.workingTime;
    });
    let totalHours = Math.floor(accumulatedTime / 60);
    let totalMins = accumulatedTime - totalHours * 60;
    return `${totalHours > 0 ? totalHours > 1 ? `${totalHours} hours` : `${totalHours} hour` : "0"} ${totalMins > 0 ? totalMins > 1 ? `${totalMins} minutes` : `${totalMins} minutes` : ""}`;
  };

  console.log(data);

  function getTimeFrame() {
    const currentDate = new Date();
  
    const sevenDaysBefore = new Date();
    sevenDaysBefore.setDate(currentDate.getDate() - 7);
  
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    const formattedStartDate = sevenDaysBefore.toLocaleDateString('en-GB', options);
    const formattedEndDate = currentDate.toLocaleDateString('en-GB', options);
  
    return `${formattedStartDate} - ${formattedEndDate}`;
  }
  
  console.log(getTimeFrame());

  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    return `${hours > 0 ? hours > 1 ? `${hours} hours` : `${hours} hour` : "0"} ${remainingMinutes > 0 ? hours > 1 ? `${remainingMinutes} minutes` : `${remainingMinutes} minute` : ""}`;
  }
  

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={3}>
              <Typography variant="h6">
                {`Weekly Timesheet (${getTimeFrame()})`}
              </Typography>
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell align="left">
              Name: {name}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Week Number: {weekNumber}
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell align="left">Day of Week</TableCell>
            <TableCell align="left">CheckIn Time</TableCell>
            <TableCell align="left">CheckOut Time</TableCell>
            <TableCell align="left">Hold Time</TableCell>
            <TableCell align="left">Working Time(Day)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            const Date = getDateFormatYYYYMMDD(row.checkedIn);
            const checkedIn = getTimeFormat(row.checkedIn);
            const checkedOut = getTimeFormat(row.checkedOut);
            return (
              <TableRow key={index}>
                <TableCell align="left">{Date}</TableCell>
                <TableCell align="left">{checkedIn}</TableCell>
                <TableCell align="left">{checkedIn === checkedOut ? "not checked out" : checkedOut}</TableCell>
                <TableCell align="left">{convertMinutesToHoursAndMinutes(row.TotalHoldTime)}</TableCell>
                <TableCell align="left">{convertMinutesToHoursAndMinutes(row.workingTime)}</TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell align="left">Total Working Time(week)</TableCell>
            <TableCell align="left">{`${CalculateTotalTime()}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell datapan={3} />
            <TableCell datapan={3} />
            <TableCell datapan={3} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
